import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./custom.scss";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import AlertWindowMaker from "./custom-plugins/AlertWindowMaker.js";
import Seo from "./custom-plugins/Seo.js";
import CurrentDate from "./custom-plugins/currentDate.js";
import FormatNumber from "./custom-plugins/formatNumber.js";
import ErrorMessage from "./custom-plugins/errorMessage.js";
import ShortenString from "./custom-plugins/shortenString.js";
import SoundSystem from "./custom-plugins/SoundSystem.js";
import VueCookies from "vue-cookies";
import Firebase from "./custom-plugins/Firebase.js";
import GoogleTag from "./custom-plugins/GoogleTagManager.js";
import FeatureUnlockedChecker from "./custom-plugins/featureUnlockedChecker.js";
import VueMeta from "vue-meta";
import whitelabelConfig from "./whitelabel-config.js";

import axios from "axios";
// import locale files
import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";

//bootstrap components
import { ModalPlugin } from "bootstrap-vue";
import { CarouselPlugin } from "bootstrap-vue";
import { PopoverPlugin } from "bootstrap-vue";

Vue.use(ModalPlugin);
Vue.use(CarouselPlugin);
Vue.use(PopoverPlugin);
import {
  BRow,
  BNavbar,
  BNavbarNav,
  BNavItem,
  BCol,
  BNav,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BForm,
  BTable,
  BPagination,
  BAlert,
  BSpinner,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";

Vue.component("BRow", BRow);
Vue.component("BNavbar", BNavbar);
Vue.component("BNavbarNav", BNavbarNav);
Vue.component("BNavItem", BNavItem);
Vue.component("BCol", BCol);
Vue.component("BNav", BNav);
Vue.component("BButton", BButton);
Vue.component("BInputGroup", BInputGroup);
Vue.component("BInputGroupAppend", BInputGroupAppend);
Vue.component("BFormInput", BFormInput);
Vue.component("BForm", BForm);
Vue.component("BTable", BTable);
Vue.component("BPagination", BPagination);
Vue.component("BAlert", BAlert);
Vue.component("BSpinner", BSpinner);
Vue.component("BCarousel", BCarousel);
Vue.component("BCarouselSlide", BCarouselSlide);

//gsap components
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
gsap.registerPlugin(MotionPathPlugin);
Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

Vue.prototype.$http = axios;
Vue.prototype.$whitelabel = whitelabelConfig;
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      if (store.getters.isMaintenanceModeEnabled) {
        store.commit("disableMaintenanceMode");
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (!error.response) {
      return Promise.resolve(error);
    }

    if (error.response.status) {
      switch (error.response.status) {
        // force logout if unauthenticated
        case 401:
          store.dispatch("destroyStore").then(router.push("/"));
          break;
        case 423:
          store.dispatch("handleTermsNotAccepted", error.response);

        case 503:
          console.log("maintenance mode?!");
          // store.commit("enableMaintenanceMode");
          // router.push("/maintenance");
          break;
        default:
          console.log(error);
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

Vue.config.performance = true;
Vue.use(VueCookies);
Vue.use(AlertWindowMaker);
Vue.use(Seo);
Vue.use(CurrentDate);
Vue.use(FormatNumber);
Vue.use(ErrorMessage);
Vue.use(ShortenString);
Vue.use(SoundSystem);
Vue.use(Firebase);
Vue.use(GoogleTag);
Vue.use(FeatureUnlockedChecker);
Vue.use(VueMeta);

i18next.init({
  lng: store.state.locale,
  resources: {},
  saveMissing: true,
  missingKeyHandler: (lng, ns, key) => {
    console.warn(`Missing translation for key: ${key} in language: ${lng}`);
  },
  interpolation: {
    prefix: "{",
    suffix: "}",
  },
});
i18next.addResourceBundle("en", "translation", translationEN, true);
i18next.addResourceBundle("de", "translation", translationDE, true);
Vue.use(I18NextVue, { i18next });

Vue.config.productionTip = false;
if (process.env.NODE_ENV === "production") {
  console.log = function () {}; // Disable all console logs in production environment
}

const sessionToken = sessionStorage.getItem("auth_token");
const localToken = localStorage.getItem("auth_token");
if (sessionToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = sessionToken;
} else if (localToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = localToken;
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
