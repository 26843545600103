// WEBSOCKET

export default {
  data() {
    return {
      privateActions: {
        ".user.wallet.updated": (e) => {
          this.$store.dispatch("user/updateWallet", e);
        },
        ".user.payment.done": (data) => {
          console.log("ws: payment done", data);
          this.$bvModal.show("modal-buy-complete");
          this.$store.commit("shop/setPaymentCompleted", true);
        },
        ".user.card.collected": (e) => {},
        ".user.mission.updated": (data) => {
          if (data.completed_at) {
            this.$store.commit("missions/setMissionComplete", true);
          }
        },
        ".user.mission.task.updated": (data) => {
          if (data.completed_at !== null) {
            this.$store.commit("missions/setUpdateMission", true);
            const element = document.getElementById("menu-item-mission");
            this.AnimationEffectsSunray(element);
          }
        },
        ".user.experience.updated": (e) => {
          this.$store.commit("user/updateUserExperience", e);
        },
        ".user.updated": (e) => console.log("WS: user updated", e),
        ".user.levelup": (data) => {
          this.googleTagLogAdvancedEvent({
            event: "level_up",
            level: "" + data.newLevel,
          });

          this.mapApiJsonUserLevelUp(data);
        },
        ".user.email.confirmation": (data) => {
          console.log(data);
          let arr = [];
          if (data.rewards) {
            if (data.rewards.gold) {
              arr.push({
                type: "gold",
                amount: data.rewards.gold,
                asset: "Currency",
              });
            }
            if (data.rewards.crowns) {
              arr.push({
                type: "crown",
                amount: data.rewards.crowns,
                asset: "Currency",
              });
            }
          }
          this.$store.commit("popups/setAlertBannerContent", {
            alertHeading: this.$t("alerts.title.success"),
            alertText: this.$t("alerts.email_confirm_bonus"),
            dismissible: true,
            countdown: 30,
            type: "check",
            variant: "success",
            otherAlertContent: arr,
          });
        },
        ".user.timer.updated": (data) => {
          const endAt = data.end_at;
          const type = data.type;

          if (type === "EXPBOOSTER") {
            this.$store.commit("user/updateBooster", endAt);

            if (!this.$store.getters["user/getDelayedBoosterUpdate"]) {
              this.$store.commit("popups/setAlertBannerContent", {
                alertHeading: this.$t("header.expbooster_title"),
                alertText: this.$t("header.expbooster_message", { 0: 50 }),
                type: "booster",
                variant: "success",
              });
            }
          }
        },
        ".user.offer.unlocked": (data) => {
          console.log("offer unlocked", data.offer);
          this.activateModalOffer(data.offer);
        },
        ".user.team.join": () => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("popup_team_join_level_reached.message"),
            alertHeading: this.$t("popup_team_join_level_reached.title"),
            type: "fellowship",
            variant: "success",
          });
        },
        ".user.treasury.updated": (data) => {
          this.$store.commit(
            "treasury/setTreasuryUpdateData",
            data.newPercentage
          );
          const treasuryMinPercentage =
            this.$store.state.generalConfig.settings[
              "treasury.exchange.watermark.low"
            ];
          if (data.newPercentage === treasuryMinPercentage) {
            this.$store.commit("treasury/setPulseMenuIcon", true);

            this.$store.commit("popups/setAlertBannerContent", {
              alertHeading: this.$t("treasury.alert.treasury_active.title"),
              alertText: this.$t("treasury.alert.treasury_active.message"),
              variant: "success",
              type: "treasury-active",
            });
          }
          if (data.newPercentage === 100) {
            this.$store.commit("treasury/setPulseMenuIcon", true);

            this.$store.commit("popups/setAlertBannerContent", {
              alertHeading: this.$t("treasury.alert.treasury_full.title"),
              alertText: this.$t("treasury.alert.treasury_full.message"),
              variant: "success",
              type: "treasury-full",
            });
          }
        },
        ".user.friend": (e) => {
          if (e.status === "ACCEPTED") {
            this.$store.commit("popups/setAlertBannerContent", {
              alertHeading: this.$t("alerts.title.success"),
              alertText: this.$t("popup_friend_request_accepted.message", {
                0: e.friend.name,
              }),
              type: "friend",
              variant: "success",
            });
            // only display badge for successful friend invite if user isn't on friends page anyway
            if (this.$route.name === "friends") return;
            else if (this.$route.path.startsWith("/friends")) {
              this.$store.commit("user/setGreenNotificationBadge", {
                location: "friendsTab",
                display: true,
              });
            } else {
              this.$store.commit("user/setGreenNotificationBadge", {
                location: "friendsTab",
                display: true,
              });
              this.$store.commit("user/setGreenNotificationBadge", {
                location: "friendsMenu",
                display: true,
              });
            }
          }
        },

        ".user.jackpot.won": (data) => {
          this.AnimationKingWin(data.jackpot);
        },
        ".user.game.betsize.updated": (data) => {
          this.$store.commit("gameplay/setCurrentBetSize", data.betsize);
        },
        ".user.kingspath.mission.completed": (result) => {
          //check if this is the tab with a kingspath related slot
          let menu = document.getElementById("sidebar-kingspath");
          if (!menu) {
            return;
          }
          this.$store.commit(
            "kingspath/setKingspathRewardsCompleted",
            result.rewards
          );

          //callback to redirect to kingspath overview
          let callback;
          callback = () => {
            this.$router.push("/kingspath");
          };

          //run animation
          this.AnimationFullAccomplishmentAnimate(
            ["kingspath", result.level + 1],
            callback
          );
        },
        ".user.kingspath.mission.updated": (data) => {
          this.$store.commit("kingspath/setKingspathMissionUpdate", data);
        },
        ".user.tournament.finish": (data) => {
          this.fetchTournamentHistoryData(data);
        },
        ".user.tournament.play": (data) => {
          this.$store.commit(
            "tournaments/setTournamentCurrentUpdateData",
            data
          );
        },
        ".user.card.gift": (data) => {
          this.$store.commit("chat/setLatestCardGift", data);
          this.playSoundChatSendReceive();
        },
        ".user.achievement.updated": (data) => {
          if (this.$route.path === "/profile/achievements") {
            this.$store.dispatch("achievements/fetchAchievements");
          }
          const old_lvl = data.original.level;
          const new_lvl = data.level;
          if (new_lvl > old_lvl) {
            this.AnimationFullAccomplishmentAnimate(["achieve", data]);

            let result = [
              {
                reward: "gold",
                amount: this.formatNumber(
                  data.achievement.level_rewards[new_lvl].gold
                ),
              },
            ];
            this.AnimationFullRewardEarnedAnimate(result, false);

            this.googleTagLogAdvancedEvent({
              event: "unlock_achievement",
              achievement_id: data.achievement.slug,
            });
          }
        },
        ".user.achievement.created": (data) => {
          if (this.$route.path === "/profile/achievements") {
            this.$store.dispatch("achievements/fetchAchievements");
          }
        },
        ".user.loyality.updated": (data) => {
          const old_lvl = data.original.level;
          const new_lvl = data.level;
          if (new_lvl > old_lvl) {
            this.AnimationFullAccomplishmentAnimate(["loyalty", new_lvl]);
          }

          this.$store.commit("user/updateLoyalty", data);
        },
        ".user.loyality.created": (data) => {
          this.$store.commit("user/updateLoyalty", data);
        },
        ".user.league.reward": (data) => {
          let action = data.action;
          if (action === "LEAGUE_REWARD_SPIN") {
            this.mapApiJsonUserLeague(data);
          }
        },
        ".user.friend.reward": (data) => {
          this.mapApiJsonFriendReward(data);
        },
        ".user.event.end": (data) => {
          this.$store.dispatch("events/updateUserEvent", data.event.id).then(
            this.$store.commit("events/setBadgeAnimation", {
              id: data.event.id,
              runAnimation: true,
            })
          );
        },
        ".user.kronenpass.end": (data) => {
          if (!this.eventsUnlocked) {
            return;
          }

          console.log("HIER WEBSOCKET PASS", data);

          this.mapApiJsonKronenpassLevelUp(data);
          // display next level as locked if it costs anything to play it
          let currentLevelIndex = data.user.level.level + 1;

          this.$store.commit("events/setCurrentLevelIndex", currentLevelIndex);
        },
        ".user.kronenpass.tier": (data) => {
          if (!this.eventsUnlocked) {
            return;
          }

          if (data.rewards.length) {
            this.mapApiJsonKronenpassTierRewards(data);
          } else {
            this.$store.commit("events/setShowEventWinner", true);
          }
        },
        ".battle.user.battle": (data) => {
          let battle = this.$store.getters["champions/getCurrentBattle"];
          if (!battle) {
            return;
          }

          // console.log("HIER WEBSOCKET",data);
          if (data.action === "start") {
            console.log("WS: battle started", data.userBattle);
            battle.started_at = data.userBattle.started_at;
            this.$store.commit("champions/setCurrentBattle", battle);
          } else if (data.action === "end") {
            console.log("WS: Battle ended", data.userBattle);
            battle.ended_at = data.userBattle.ended_at;

            this.$store.commit("champions/setCurrentBattle", battle);
            this.$store.dispatch("champions/handleNewBattleEnd", battle);
            this.$store.commit("champions/setCurrentOpponentId", null);
          }
        },
      },
      publicActions: {
        ".jackpots.update": (data) => {
          this.$store.commit("gameplay/updateJackpots", data.data);
        },
        ".league.update": (data) => {
          const userId = this.$store.getters["user/currentUser"].id;
          const users = data.users;
          for (let i = 0; i < users.length; i++) {
            if (users[i] === userId) {
              this.mapUserLeagueChange(data);
              return;
            }
          }
        },
        ".event.update": (data) => {
          if (!data.newIngameEvents) return;
          this.$store.dispatch("events/getEvents").then(() => {
            data.newIngameEvents.forEach((event) =>
              this.$store.commit("events/setBadgeAnimation", {
                id: event.id,
                runAnimation: true,
              })
            );
          });
        },
      },
    };
  },
};
