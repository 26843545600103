import Vue from "vue";
import Router from "vue-router";
import store from "./store/store.js";
import LobbyPage from "./views/LobbyPage.vue";
import LandingPage from "./views/LandingPage.vue";
import { getUserObject } from "@/API/profile.js";
import gsap from "gsap";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        default: LobbyPage,
        login: LandingPage,
      },
      beforeEnter(to, from, next) {
        store.dispatch("getPublicConfig");
        next();
      },
      children: [
        {
          path: "/games/:gameSelection",
          name: "GameGrid",
          component: require("./views/LobbyPage.vue").default,
          meta: {
            requiresAuth: true,
            seoTitle: "Spiele",
          },
          beforeEnter(to, from, next) {
            store.commit("setIsPageLoading", false);
            next();
          },
        },

        {
          path: "/games-by-provider/:providerId",
          name: "ProviderSlots",
          component: require("./views/LobbyPage.vue").default,
          meta: {
            requiresAuth: true,
            seoTitle: "Spiele",
          },
          beforeEnter(to, from, next) {
            store.commit("setIsPageLoading", false);
            next();
          },
        },
      ],
    },
    {
      path: "/login",
      name: "login",
      meta: {
        requiresAuth: false,
        seoTitle: "Login",
      },
      component: LandingPage,
    },

    {
      path: "/missions",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/treasury",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/bonus-slot",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/bonus-slot-elixier",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/tip-king",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/levelup",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/spinwin",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/winter-bonus",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/olympia",
      name: "home2",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/LobbyPage.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/loginFromAcp/:token",
      name: "AcpLogin",

      beforeEnter(to, from, next) {
        let bearerToken = to.params.token;
        store.dispatch("getPublicConfig");
        store.dispatch("resetStore");

        store.commit("auth/auth_pending", bearerToken);
        getUserObject()
          .then((res) => {
            store.dispatch("auth/acpLogin", res);
            next("/");
          })
          .catch((e) => {
            next("/");

            if (
              e.data &&
              e.data.message &&
              e.data.message === "Unauthenticated."
            ) {
              console.log("invalid bearer token");
            }
          });
      },
    },

    {
      path: "/kingspath",
      name: "kingspath",
      component: () =>
        import(/* webpackChunkName: "kingspath" */ "./views/KingspathPage.vue"),
      meta: {
        requiresAuth: true,
        seoTitle: "Königsweg",
      },
      beforeEnter(to, from, next) {
        const user = store.getters["user/currentUser"];
        if (
          parseInt(user.level) <
          store.getters["getGeneralConfig"].settings["kingspath.entry.level"]
        ) {
          next("/");
        }
        next();
      },
    },

    {
      path: "/kingspath/game/:gameId",
      name: "kingspath-game",
      component: () =>
        import(/* webpackChunkName: "slot" */ "./views/SlotPage.vue"),
      meta: {
        requiresAuth: true,
        seoTitle: "Im Königsweg-Spiel",
      },
      props: { menu: "kingspath" },
    },

    {
      path: "/tournament/game/:gameId",
      name: "tournament-game",
      component: () =>
        import(/* webpackChunkName: "slot" */ "./views/SlotPage.vue"),
      meta: {
        requiresAuth: true,
        seoTitle: "Im Turnier-Spiel",
      },
      props: { menu: "tournament" },
    },

    {
      path: "/champions/game/:gameId",
      name: "champions-game",
      component: () =>
        import(/* webpackChunkName: "slot" */ "./views/SlotPage.vue"),
      meta: {
        requiresAuth: true,
        seoTitle: "Im Champions-Spiel",
      },
      props: { menu: "champions" },
    },

    {
      path: "/game/:gameId",
      name: "game",
      component: () =>
        import(/* webpackChunkName: "slot" */ "./views/SlotPage.vue"),
      meta: {
        requiresAuth: true,
        seoTitle: "Im Spiel",
      },
      props: { menu: "jackpot" },
    },
    {
      path: "/cards",
      component: () =>
        import(/* webpackChunkName: "cards" */ "./views/CardsPage.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "album",
          component: () =>
            import(
              /* webpackChunkName: "cards" */ "./components/cards/album-container.vue"
            ),
          meta: {
            seoTitle: "Sammelkarten",
          },
        },
        {
          path: "/cards/winners",
          name: "winners",
          component: () =>
            import(
              /* webpackChunkName: "cards" */ "./components/cards/winners-container.vue"
            ),
          meta: {
            seoTitle: "Verlosung",
          },
        },
      ],
    },
    {
      path: "/teams",
      component: () =>
        import(/* webpackChunkName: "friends" */ "./views/TeamsPage.vue"),

      children: [
        {
          path: "",
          name: "team",
          meta: {
            requiresAuth: true,
            seoTitle: "Team",
          },
          component: () =>
            import(
              /* webpackChunkName: "friends" */ "./components/team/team-container.vue"
            ),
        },

        {
          path: "/teams/friends",
          component: () =>
            import(
              /* webpackChunkName: "friends" */ "./components/friends/friends-container.vue"
            ),
          meta: {
            seoTitle: "Freunde",
          },
        },
        {
          path: "/teams/chat",
          name: "chat",
          component: () =>
            import(
              /* webpackChunkName: "friends" */ "./components/chat/chat-container.vue"
            ),
          beforeEnter(to, from, next) {
            if (!store.getters.teamsEnabled) {
              next("/teams/friends");
            } else if (!store.getters["teams/isTeamMember"]) {
              next("/teams");
            } else {
              next();
            }
          },
          meta: {
            seoTitle: "Chat",
          },
        },
      ],
    },
    {
      path: "/champions",
      name: "champions",
      component: () =>
        import(/* webpackChunkName: "champions" */ "./views/ChampionsPage.vue"),
      meta: {
        requiresAuth: true,
        seoTitle: "Champions",
      },
    },
    {
      path: "/contact",
      component: () =>
        import(/* webpackChunkName: "faq" */ "./views/FAQPage.vue"),
      props: true,

      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/profile",
      component: () =>
        import(/* webpackChunkName: "profile" */ "./views/ProfilePage.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "",
          name: "profile",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "./components/profile/profile-container.vue"
            ),
          meta: {
            requiresAuth: true,
            seoTitle: "Profil",
          },
        },
        {
          path: "/profile/voucher",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "./components/profile/voucher.vue"
            ),
          meta: {
            requiresAuth: true,
            seoTitle: "Gutschein",
          },
        },
        {
          path: "/profile/stats",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "./components/profile/user-stats.vue"
            ),
          meta: {
            requiresAuth: true,
            seoTitle: "Statistiken",
          },
        },
        {
          path: "/profile/logs",
          component: () =>
            import(
              /* webpackChunkName: "profile" */ "./components/profile/user-logs.vue"
            ),
          meta: {
            requiresAuth: true,
            seoTitle: "Logs",
          },
        },
        {
          path: "/profile/loyalty",
          name: "loyalty",
          component: () =>
            import(/* webpackChunkName: "loyalty" */ "./views/LoyaltyPage.vue"),
          meta: {
            requiresAuth: true,
            seoTitle: "Kronen-Treue",
          },
        },
        {
          path: "/profile/achievements",
          component: () =>
            import(
              /* webpackChunkName: "achievements" */ "./views/UserAchievementsPage.vue"
            ),
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              name: "achievements",
              component: () =>
                import(
                  /* webpackChunkName: "achievements" */
                  "./components/user-achievements/user-achievements-container.vue"
                ),
              meta: {
                seoTitle: "Ewige Erfolge",
              },
            },
            {
              path: "/profile/achievements/trophies",
              name: "achievements-trophies",
              component: () =>
                import(
                  /* webpackChunkName: "achievements" */ "./components/user-achievements/top-games.vue"
                ),
              meta: {
                seoTitle: "Ewige Pokale",
              },
            },
          ],
        },
      ],
      beforeEnter(to, from, next) {
        const user = store.getters["user/currentUser"];
        // if (parseInt(user.level) < 8) {
        //   next("/");
        // }
        next();
      },
    },
    {
      path: "/league",
      component: () =>
        import(
          /* webpackChunkName: "user-league" */ "./views/UserLeaguePage.vue"
        ),
      children: [
        {
          path: "",
          name: "league",
          component: () =>
            import(
              /* webpackChunkName: "user-league" */ "./components/user-league/user-league-container.vue"
            ),
          meta: {
            seoTitle: "Adels-Liga",
          },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/tournament",
      component: () =>
        import(
          /* webpackChunkName: "tournaments" */ "./views/TournamentPage.vue"
        ),
      props: (route) => ({ tab: route.name }),
      beforeEnter(to, from, next) {
        const user = store.getters["user/currentUser"];
        if (
          parseInt(user.level) <
          store.getters["getGeneralConfig"].settings["tournament.entry.level"]
        ) {
          next("/");
        }
        next();
      },
      children: [
        {
          path: "",
          name: "tournament",
          component: () =>
            import(
              /* webpackChunkName: "tournaments" */ "./components/tournament/main-page.vue"
            ),
          meta: {
            seoTitle: "Turniere",
          },
        },
        {
          path: "/tournament/achievement",
          name: "tournament-achievement",
          component: () =>
            import(
              /* webpackChunkName: "tournaments" */ "./components/tournament/tournament-achievement-page.vue"
            ),
          meta: {
            seoTitle: "Turnier-Erfolge",
          },
        },
        {
          path: "/tournament/history",
          name: "tournament-history",
          component: () =>
            import(
              /* webpackChunkName: "tournaments" */ "./components/tournament/history-page.vue"
            ),
          meta: {
            seoTitle: "Turnier-Ergebnisse",
          },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/legal",
      name: "legal",
      component: () =>
        import(/* webpackChunkName: "legal" */ "./views/LegalPage.vue"),
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "/legal/tac",
          alias: ["/tac", "/agb"],
          name: "terms-and-conditions",
          component: () =>
            import(
              /* webpackChunkName: "legal" */ "./components/legal/tac-container.vue"
            ),
          meta: {
            requiresAuth: false,
            seoTitle: "AGB",
          },
        },
        {
          path: "/legal/privacy",
          alias: "/privacy",
          name: "privacy",
          component: () =>
            import(
              /* webpackChunkName: "legal" */ "./components/legal/privacy-container.vue"
            ),
          meta: {
            requiresAuth: false,
            seoTitle: "Datenschutzerklärung",
          },
        },
        {
          path: "/legal/imprint",
          alias: "/imprint",
          name: "imprint",
          component: () =>
            import(
              /* webpackChunkName: "legal" */ "./components/legal/imprint-container.vue"
            ),
          meta: {
            requiresAuth: false,
            seoTitle: "Impressum",
          },
        },
        {
          path: "/legal/raffle_tac",
          alias: "/raffle_tac",
          name: "raffle_tac",
          component: () =>
            import(
              /* webpackChunkName: "legal" */ "./components/legal/raffle_tac-container.vue"
            ),
          meta: {
            requiresAuth: false,
            seoTitle: "Gewinnspielbedingungen",
          },
        },
      ],
    },

    // TODO: group under parent /email route
    {
      path: "/management",
      name: "management",
      component: () =>
        import(
          /* webpackChunkName: "management" */ "./views/ManagementPage.vue"
        ),
      meta: {
        requiresAuth: false,
      },
      children: [
        {
          path: "/email/confirmation/success",
          name: "email-confirm-success",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/email-success.vue"
            ),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/email/confirmation/failure",
          name: "email-confirm-failure",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/email-failure.vue"
            ),
          meta: {
            requiresAuth: false,
          },
          beforeEnter(to, from, next) {
            // prevent users from accessing email confirm failure site w/o msg query
            if (!to.query.msg) {
              next("/");
            } else {
              next();
            }
          },
        },
        {
          path: "/email/change/success",
          name: "email-change-success",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/email-success.vue"
            ),
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/email/change/failure",
          name: "email-failure",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/email-failure.vue"
            ),
          meta: {
            requiresAuth: false,
          },
          beforeEnter(to, from, next) {
            // prevent users from accessing email change failure site w/o msg query
            if (!to.query.msg) {
              next("/");
            } else {
              next();
            }
          },
        },
        {
          path: "/email/unsubscribe",
          name: "email-unsubscribe",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/email-unsubscribe.vue"
            ),
          meta: {
            requiresAuth: false,
          },
          beforeEnter(to, from, next) {
            // prevent users from accessing unsubscribe site w/o hash
            if (!to.query.hash) {
              next("/");
            } else {
              next();
            }
          },
        },
        {
          path: "/password/reset",
          name: "password-reset",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/password-reset.vue"
            ),
          meta: {
            requiresAuth: false,
          },
          beforeEnter(to, from, next) {
            // prevent users from accessing password reset site w/o token
            if (!to.query.token) {
              next("/");
            } else {
              next();
            }
          },
        },
        {
          path: "/welcome",
          name: "landing-page-redirect",
          component: () =>
            import(
              /* webpackChunkName: "management" */ "./components/others/landing-page-redirect.vue"
            ),
          meta: {
            requiresAuth: false,
          },
          beforeEnter(to, from, next) {
            // log out of possibly existing session
            store.dispatch("destroyStore");
            next();
          },
        },
      ],
    },

    {
      path: "*",
      name: "PageNotFound",
      component: () =>
        import(
          /* webpackChunkName: "404" */ "./components/others/NotFound.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/maintenance",
      name: "maintenance",
      meta: {
        seoTitle: "Wartungsarbeiten",
      },
      component: () =>
        import(
          /* webpackChunkName: "Maintenance" */ "./views/MaintenancePage.vue"
        ),
      beforeEnter(to, from, next) {
        if (!store.getters.isMaintenanceModeEnabled) {
          next("/");
        } else {
          next();
        }
      },
    },
  ],
});

// router.addRoutes(seoRoutes);

if (process.env.NODE_ENV !== "production") {
  router.addRoutes([
    {
      path: "/testo",
      name: "testo",
      component: () =>
        import(/* webpackChunkName: "testo" */ "./views/TestoPage.vue"),
      meta: {
        requiresAuth: false,
      },
    },
  ]);
}

router.beforeEach((to, from, next) => {
  Object.keys(to.query).forEach((key, val) => {
    //do not accept urls with unknown parameters
    if (!allowedParameters.includes(key)) {
      next(to.path);
    }
    //empty parameter. something is fishy
    if (!to.query[key]) {
      next(to.path);
    }
    if (to.query.sessionId && to.query.redirectResult) {
      store.commit("shop/setRedirectResult", to.query.redirectResult);
      store.commit("shop/setSessionID", to.query.sessionId);
    }
  });

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //redirect non logged user to main page
    if (!store.getters["auth/isLoggedIn"]) {
      store.dispatch("destroyStore");
      next("/");
      return;
    }

    store.dispatch("setIsPageLoading", true);
    next();
  } else if (to.query.token) {
    // log out if there's a token involved
    store.dispatch("destroyStore");
    if (to.query.email) {
      // proceed with full query information to password reset page
      next();
    } else {
      next("/");
    }
  } else if (to.query.inviterToken) {
    // log out of potential existing session if inviterToken is present
    store.dispatch("destroyStore");
    store.commit("auth/setReferrerToken", to.query.inviterToken);
    // proceed to login for referred new users
    next("/");
  }
  if (to.query.startPlaying) {
    let gameId = parseInt(to.query.startPlaying);

    if (store.getters["auth/isLoggedIn"]) {
      next(`/game/${gameId}`);
    } else {
      store.commit("gameplay/setRedirectGame", gameId);
      next({ path: "/" });
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  //kill all gsap animations
  gsap.globalTimeline.clear();

  //redirect from non www to www in production mode
  const url = window.location.href;
  if (process.env.NODE_ENV === "production") {
    if (url.indexOf("https://kleinekrone") === 0) {
      window.location = url.replace("https://", "https://www.");
    }
  }

  if (store.getters["auth/isLoggedIn"] && window.innerWidth >= 1024) {
    if (needsAutoRefresh()) {
      store.commit("resetAutoRefresh");
      router.go(0);
    }
  }

  //scroll to top
  window.scrollTo(0, 0);
});
export default router;

const needsAutoRefresh = () => {
  if (!store.getters["getAutoRefresh"]) {
    store.commit("resetAutoRefresh");
    return false;
  }
  const timeDiff = 1000 * 60 * 60 * 2; // 2 hours
  // const timeDiff = 1000 * 60 * 5; // 5 minutes
  const anHourAgo = Date.now() - timeDiff;
  const lastRefresh = new Date(store.getters["getAutoRefresh"]);
  return lastRefresh.getTime() < anHourAgo;
};

const allowedParameters = [
  "locale",
  "landingToken",
  "rematch",
  "lp_redirect",
  "email",
  "token",
  "hash",
  "redirectResult",
  "resultCode",
  "sessionId",
  "type",
];
